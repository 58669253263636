<template>
  <div class="login_logs_view">
    <div class="content-select">
      <div class="select-content">
        <el-form ref="filterForm" :model="queryParams">
          <el-row class="row-first">
            <el-col :span="5">
              <el-form-item label="登录地址" prop="title">
                <el-input v-model="queryParams.cliIp" placeholder="请输入登录地址" clearable style="width: 220px" />
              </el-form-item>
            </el-col>
            <el-col :span="5">
              <el-form-item label="用户名称" prop="operName">
                <el-input v-model="queryParams.userName" placeholder="请输入操作人员" clearable style="width: 220px" @keyup.enter.native="handleQuery" />
              </el-form-item>
            </el-col>
            <el-col :span="5">
              <el-form-item label="状态" prop="status">
                <el-select v-model="queryParams.oprtStatus" placeholder="操作状态" clearable style="width: 220px">
                  <el-option v-for="dict in operateSta" :key="dict.value" :label="dict.label" :value="dict.value" />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="5">
              <el-form-item label="登录时间" prop="loginTime">
                <el-date-picker v-model="queryParams.oprtTimeRange" style="width: 220px" value-format="yyyy-MM-dd" type="daterange" range-separator="-" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="4">
              <el-button type="primary" :disabled="loading" @click="handleQuery">查询</el-button>
              <el-button type="default" @click="resetQuery">重置</el-button>
            </el-col>
          </el-row>
        </el-form>
      </div>
    </div>
    <div class="content-main">
      <div class="top-left" style="margin-bottom: 5px">
        <el-button type="danger" @click="handleDelete" :disabled="selections.length == 0" plain icon="el-icon-delete">删除</el-button>
        <el-button type="danger" @click="handleClean" plain icon="el-icon-delete">清空</el-button>
<!--        <el-button type="primary" plain icon="el-icon-delete">解锁</el-button>-->
        <el-button type="warning" @click="alertExportLogs" plain icon="el-icon-download">导出</el-button>
      </div>
      <div class="content-table">
        <el-table :data="records" ref="table" stripe :header-cell-style="handleHeaderCellStyle" border v-loading="loading" style="width: 100%" @selection-change="handleSelection">
          <el-table-column type="selection" fixed="left" />
          <el-table-column label="访问编号" align="center" prop="id" />
          <el-table-column label="用户名称" align="center" prop="userName" />
          <el-table-column label="登录地址" align="center" prop="cliIp" />
          <el-table-column label="登录地点" :show-overflow-tooltip="true" align="center">
            <template slot-scope="scope"> {{ scope.row.cliCountry }} {{ scope.row.cliProvince }} {{ scope.row.cliCity }} </template>
          </el-table-column>
          <el-table-column label="浏览器" align="center" prop="cliBrowser" />
          <el-table-column label="操作系统" align="center" prop="cliOs" />
          <el-table-column label="操作来源" align="center" prop="oprtSource" :formatter="handleTableValueFormat"/>
          <el-table-column label="登录状态" align="center" prop="oprtStatus">
            <template slot-scope="scope">
              <span v-if="scope.row.oprtStatus == 'Y'">
                <el-tag>成功</el-tag>
              </span>
              <span v-else>
                <el-tag type="danger">失败</el-tag>
              </span>
            </template>
          </el-table-column>
          <el-table-column label="操作信息" align="center" :show-overflow-tooltip="true" prop="errorMessage" :formatter="handleTableValueFormat">
          </el-table-column>
          <el-table-column label="登录日期" align="center" prop="oprtTime">
            <template slot-scope="scope">
              <span>{{ fmtOrderTime(scope.row.oprtTime) }}</span>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="content-page">
        <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange" :page-sizes="[15, 30, 50, 100]" :page-size="queryParams.size" layout="total, sizes, prev, pager, next" :total="total"></el-pagination>
      </div>
    </div>

    <el-dialog width="30%" append-to-body :visible.sync="exportLogsVisible">
      <div style="margin: 10px 20px">
        <p style="font-size: 20px">你是否要导出已勾选的{{ exportCounts }}数据。</p>
      </div>
      <div slot="footer" class="button-footer" style="text-align: center">
        <el-button type="default" @click="exportLogsVisible = false" class="button-left">取消</el-button>
        <el-button type="primary" @click="handleExportLogs">确认</el-button>
      </div>
    </el-dialog>
    <el-dialog width="30%" append-to-body :visible.sync="deleteVisible">
      <div style="margin: 10px 20px">
        <p style="font-size: 20px">是否确认删除日志编号为"{{ deleteIds }}"的数据项？</p>
      </div>
      <div slot="footer" class="button-footer" style="text-align: center">
        <el-button type="default" @click="deleteVisible = false" class="button-left">取消</el-button>
        <el-button type="primary" @click="handleDeletedLogs">确认</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { tableStyle } from '@/util/mixins';
import { getList, getDetail, ReqLogsExport, deleteOprtLogs, cleanOprtLogs } from '@/service/sys/oprt-log';

export default {
  name: 'logs',
  mixins: [tableStyle],
  data() {
    return {
      operateSta: [
        { label: '成功', value: 'Y' },
        { label: '失败', value: 'N' }
      ],
      operatteSource: [],
      // 查询参数
      queryParams: {
        page: 1,
        size: 15,
        cliIp: undefined,
        userName: undefined,
        oprtStatus: undefined,
        bizName: undefined,
        dateRange: []
      },
      detailInfo: {},
      records: [],
      loading: false,
      total: 0,
      paging: {
        total: 0,
        size: 15,
        current: 1
      },
      pageSizes: [15, 30, 50, 100],
      selections: [],
      detailVisible: false,
      exportLogsVisible: false,
      exportCounts: 0,
      deleteVisible: false,
      deleteIds: []
    };
  },

  created() {
    this.getDicts('sys-oprt-source').then(({data}) => this.operatteSource = data)
    this.getList();
  },
  methods: {
    /** 查询登录日志 */
    getList() {
      this.loading = true;
      this.queryParams.bizName = '登录注册';
      getList(this.queryParams).then((response) => {
        if (response.success) {
          this.records = response.data.records;
          this.total = response.data.total;
        }
        this.loading = false;
      });
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.page = 1;
      this.getList();
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.queryParams.userName = undefined;
      this.queryParams.oprtStatus = undefined;
      this.queryParams.oprtTimeRange = [];
      this.queryParams.cliIp = undefined;
    },
    handleSizeChange(val) {
      this.queryParams.page = 1;
      this.queryParams.size = val;
      this.getList();
    },
    handleCurrentChange(val) {
      console.log('下一页');
      this.queryParams.page = val;
      this.getList();
    },
    /* 时间格式化 */
    fmtDate(row, column) {
      let date = row[column.property];
      if (date == undefined || date == null) {
        return '';
      }
      return this.$moment(date).format('YYYY-MM-DD');
    },
    handleSelection(value) {
      this.selections = value;
    },
    fmtOrderTime(val) {
      if (val == undefined || val == null) {
        return '--';
      }
      return val.substring(0, 11);
    },
    formatBizType(val) {
      let bizType = this.operatteType.find((item) => item.value == val).label;
      if (bizType != null) {
        return bizType;
      }
      return val;
    },
    handleView(row) {
      this.detailVisible = true;
      getDetail(row.id).then((response) => {
        if (response.success) {
          this.detailInfo = response.data;
        }
      });
    },
    alertExportLogs() {
      if (this.selections.length == 0) {
        this.exportCounts = this.total;
      } else {
        this.exportCounts = this.selections.length;
        this.queryParams.ids = this.selections.map((item) => item.id);
      }
      this.exportLogsVisible = true;
    },
    async handleExportLogs() {
      this.queryParams.bizName = '登录注册';
      const rsp = await ReqLogsExport(this.queryParams);
      this.exportLogsVisible = false;
      this.downloadFile(rsp);
    },
    handleDelete() {
      if(!this.selections.length > 0){
        this.toast('请选择删除的日志记录', 'warning');
        return
      }
      this.deleteIds = this.selections.map((item) => item.id);
      this.deleteVisible = true
    },
    handleDeletedLogs () {
      this.queryParams.ids = this.deleteIds;
      this.queryParams.operType = 'DELETED';
      deleteOprtLogs(this.queryParams).then((response) => {
        if (response.success) {
          this.toast('删除成功', 'success');
          this.queryParams.ids = [];
          this.getList();
        }
      });
      this.deleteVisible = false
    },
    handleClean() {
      this.$confirm('是否确认清空所有查询出来的操作日志数据项？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.queryParams.operType = 'CLEAN';
          this.queryParams.bizName = '登录注册';
          cleanOprtLogs(this.queryParams).then((response) => {
            if (response.success) {
              this.toast('清空成功', 'success');
              this.queryParams.ids = [];
              this.getList();
            }
          });
        })
        .catch(() => {
          this.toast('取消清空', 'warning');
        });
    },
    handleTableValueFormat(row, column) {
      let value = row[column.property];
      switch (column.label) {
        case '操作信息':
          if (row.oprtStatus == 'Y') {
            if (row.bizType == 'SIGNIN') {
              return '登录成功';
            } else if (row.bizType == 'LOGOUT') {
              return '退出成功';
            }
          } else {
            return row.errorMessage;
          }
          break;
        case '操作来源':
          var oprtSource = this.operatteSource.find((item) => item.value == row.oprtSource);
          if (oprtSource != null) {
            return oprtSource.label;
          }
          return row.oprtSource;
        default:
          return value;
      }
    }
  }
};
</script>
<style lang="scss" scoped>
// .login_logs_view {
// }
.detail-row {
  text-align: left;
  margin-top: 30px;
  margin-left: 150px;

  .detail-row-info {
    display: flex;
  }
}
</style>
